.container {
	max-width  : 1440px;
	margin-top : 64px;
	background : #F7F7FA;
	padding    : 0;
}
.title {
	margin     : 0 16px;
	min-height : calc(100vh - 178px);
}
h3 {
	padding-top    : 20px;
	font-weight    : 500;
	font-size      : 12px;
	line-height    : 16px;
	text-transform : uppercase;
	color          : #716F7D;
}
.dashTitle {
	font-weight   : 900;
	font-size     : 24px;
	line-height   : 36px;
	color         : #25232C;
	margin-bottom : 16px;
}
.dashTitleIcon {
	margin-right : 14.5px;
	width        : 20px;
	height       : 20px;
}
.stateType {
	/*height        : 109px;*/
	background    : linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
	border-radius : 8px;
	padding  : 20px;
}
.creditInfo {
	height        : auto;
	background    : linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
	border-radius : 8px;
	padding-left  : 20px;
}
.stateTitleText {
	font-weight : 500;
	font-size   : 12px;
	line-height : 16px;
	color       : #716F7D;
}
.underAnalysis{
	font-weight: bold;
	font-size: 12px;
	line-height: 16px;
	color: black;
}
.textProgress {
	position       : relative;
	top            : 4px;
	right          : 29px;
	font-weight    : bold;
	font-size      : 10px;
	line-height    : 16px;
	text-align     : center;
	text-transform : uppercase;
	color          : #FFFFFF;
}
.statusDescription {
	font-weight : 900;
	font-size   : 18px;
	line-height : 28px;
}
.buttonProcDoc {
	width         : 100%;
	height        : 48px;
	background    : linear-gradient(180deg, #214FA4 0%, #0C3A8E 100%);
	border-radius : 8px;
	font-style    : normal;
	font-weight   : bold;
	font-size     : 16px;
	line-height   : 16px;
	margin-top    : 16px;
	color         : #FFFFFF !important;
	cursor        : pointer;
}
.middleText{
	margin-left: 20px;
	max-width: 56%!important;
}
.shadowImage {
	margin : 10px 20px ;
}
.processInfoBlock {
	display       : flex;
	align-items   : center;
	align-content : start;
	flex-wrap     : wrap;
}
.upperInfo {
	width   : 100%;
	display : flex;
    margin: 10px 0 15px
}
.lowerInfo {
	margin-top : 24px;
	width      : 100%;
	display    : flex;
}
.creditImg {
	display : flex;
	width   : 32px;
	height  : 32px;
	margin  : 0 25px 0 5px;
}
.upperText {
	display   : inline-flex;
	flex-flow : column;
}
.targetTerm {
	display      : flex;
	flex-flow    : column;
	margin-right : 20px;
}
.verticalDash {
	border-right : #CFCBE2 solid 1px;
}
.amount {
	display     : flex;
	flex-flow   : column;
	margin-left : 20px;
}
.creditTypeTitle {
	font-style  : normal;
	font-weight : 500;
	font-size   : 12px;
	line-height : 16px;
	color       : #8D8CA1;
}
.creditTypeText {
	font-style  : normal;
	font-weight : 900;
	font-size   : 18px;
	line-height : 24px;
	color       : #25232C;
}
a:hover {
	text-decoration : none;
}
.managerBlock {
	height        : 170px;
	background    : linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
	box-shadow    : 0px 1px 2px rgba(37, 35, 44, 0.08);
	border-radius : 8px;
	padding       : 20px 0 20px 20px;
}
.managerInfo {
	max-width : 66%;
	display   : flex;
	flex-wrap : wrap;
}
.managerPhoto {
	margin    : -25px -10px -15px auto;
	min-width : 132px;
	height    : 132px;
}
.circle3 {
	display         : flex;
	justify-content : end;
	width           : 110px;
	height          : 110px;
	border          : 1px solid rgba(57, 182, 92, 0.06);
	border-radius   : 50%;
}
.circle2 {
	margin        : 8px 0 0 8px;
	border        : 1px solid rgba(57, 182, 92, 0.12);
	width         : 92px;
	height        : 92px;
	border-radius : 50%;
}
.circle1 {
	margin        : 7px 0 0 7px;
	border        : 1px solid rgba(57, 182, 92, 0.2);
	width         : 76px;
	height        : 76px;
	border-radius : 50%;
}
.photo {
	margin        : 5px 0 0 5px;
	width         : 40px;
	height        : 40px;
	border-radius : 50%;
}
.circle {
	position      : relative;
	bottom        : 17px;
	right         : -53px;
	height        : 14.19px;
	width         : 14.19px;
	border-radius : 50%;
	background    : #39B65C;
	border        : 2px solid #FFFFFF;
}
.managerTitle {
	font-style  : normal;
	font-weight : 500;
	font-size   : 12px;
	line-height : 16px;
	color       : #8D8CA1;
}
.managerName {
	font-style  : normal;
	font-weight : bold;
	font-size   : 14px;
	line-height : 28px;
	color       : #25232C;
	margin-top  : -22px;
}
.managerEmail {
	font-style  : normal;
	font-weight : 500;
	font-size   : 14px;
	line-height : 28px;
	color       : #174DAF;
	margin-top  : -28px;
}
.telefonelink {
	font-style  : normal;
	font-weight : 500;
	font-size   : 14px;
	line-height : 24px;
	color       : #25232C !important;
}
.whatsapp {
	font-weight   : 500;
	font-size     : 14px;
	line-height   : 24px;
	color         : #4AC959 !important;
	padding-right : 15px;
}
.form-control {
	font-weight   : 500;
	font-size     : 14px;
	line-height   : 24px;
	color         : #25232C !important;
	height        : 48px;
	background    : #FFF;
	border        : 2px solid #EAE8F3;
	box-sizing    : border-box;
	border-radius : 8px;
}
div.form-groupPassword label {
	font-weight : 500;
	font-size   : 12px;
	line-height : 16px;
	color       : #716F7D;
	margin      : 0;
	cursor      : pointer;
}
.modal-dialog {
	max-width : 343px !important;
}
@media (min-width : 768px) {
	.title {
		min-height : calc(100vh - 241px);
		margin     : 0 32px;
	}
	h3 {
		padding-top : 32px;
	}
	.dashTitle {
		margin-bottom : 24px;
	}
	.stateTypeBlock {
		margin-left  : -12px !important;
		margin-right : -12px !important;
	}
	.stateTypeContainer {
		padding-left  : 12px !important;
		padding-right : 12px !important;
        margin-bottom: 20px !important;
	}
	.stateType {
		margin-bottom : 0;
	}
	.middleText {
		max-width   : 55% !important;
	}
	.shadowImage {
		margin : 32px 20px 32px auto;
	}
	.managerBlock {
		height  : 104px;
		padding : 0 20px 0 0;
	}
	.managerPhoto {
		min-width : 105px;
		display   : block;
		position  : relative;
		top       : 14px;
		left      : -1px;
		margin    : 0;
	}
	.circle3 {
		position : absolute;
		width    : 110px;
		height   : 110px;
		margin   : 0;
	}
	.circle2 {
		position : absolute;
		top      : 8px;
		left     : 8px;
		margin   : 0;
	}
	.circle1 {
		position : absolute;
		top      : 7px;
		left     : 7px;
		margin   : 0;
	}
	.photo {
		position : relative;
		top      : 0px;
		left     : 10px;
		margin   : 0;
	}

	.photoProcess {
		position : relative;
		top      : 0px;
		margin   : 0;
		border-radius: 50%;
		width: 75px;
	}

	.circle {
		position : absolute;
		bottom   : 9px;
		right    : 7px;
		margin   : 0;
	}
	.managerTitle {
		margin-right : 0;
	}
	.managerName {
		margin : 0;
	}
	.managerEmail {
		margin : 0;
	}
	.modal-dialog {
		max-width : 552px !important;
	}
}
@media(min-width: 1024px){
	.middleText {
		max-width   : 60% !important;
	}
}
@media (min-width : 1200px) {
	.title {
		margin : 0 156px;
	}
	h3 {
		padding-top : 40px;
	}
	.middleText {
		max-width   : 66% !important;
	}
}





