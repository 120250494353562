.container {
	max-width  : 1440px;
	margin-top : 64px;
	background : #F7F7FA;
	padding    : 0;
}
a:hover {
	text-decoration : none;
}
.title {
	min-height : calc(100vh + 178px);
	height     : 100%;
	margin     : 0;
}
.docTitle {
	padding-top   : 16px;
	font-weight   : 900;
	font-size     : 24px;
	line-height   : 36px;
	color         : #25232C;
	margin-bottom : 16px;
	margin-left   : 52px;
}
.docTitleIcon {
	margin-right : 14px;
	width        : 20px;
	height       : 20px;
}
h1 {
	font-weight : 900;
	font-size   : 24px;
	line-height : 36px;
	color       : #25232C;
}
h2 {
	font-weight   : 900;
	font-size     : 18px;
	line-height   : 28px;
	color         : #25232C;
	margin-bottom : 32px;
}
.card {
	border : 0 !important;
}
.card-header {
	padding         : 0 35px;
	height          : 58px;
	border-bottom   : 2px solid #ECEAF3;
	display         : flex;
	align-items     : center;
	justify-content : center;
	background      : linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
	box-shadow      : 0 1px 2px rgba(37, 35, 44, 0.08);
	border-radius   : 8px;
}
.invalid-feedback {
	line-height : 16px;
}
ul#myTab a.nav-link {
	font-weight    : 600;
	font-size      : 13px;
	line-height    : 24px;
	letter-spacing : 0.02em;
	color          : #716F7D;
	padding        : 18px 0 16px 0;
}
ul#myTab a.nav-link.active {
	color         : #174DAF;
	border-bottom : #174DAF 2px solid;
	padding       : 18px 0 16px 0;
}
.firstHolderImg {
	width        : 20px;
	height       : 17px;
	margin-right : 10px;
	fill         : #716F7D;
}
ul#myTab a.nav-link.active > .firstHolderImg, ul#myTab a.nav-link.active > .secondHolderImg {
	fill   : #174DAF;
	/*stroke : #174DAF;*/
}
.secondHolderImg {
	width        : 24px;
	height       : 24px;
	margin-right : 7px;
	fill         : #716F7D;
	/*stroke       : #716F7D;*/
}
.card-body {
	margin    : 38px 35px 40px 35px;
	max-width : 375px;
}
.summaryState {
	position : sticky;
	top      : 6rem;
	z-index  : 1000;
	height   : calc(100vh - 430px);
}
.statusTitle {
	font-weight   : bold;
	font-size     : 14px;
	line-height   : 24px;
	color         : #25232C;
	margin-bottom : 4px;
	padding-top   : 16px;
}
.statusImg {
	width        : 24px;
	height       : 24px;
	margin-right : 12px;
}
label {
	font-weight : 500;
	font-size   : 12px;
	line-height : 16px;
	color       : #716F7D;
	margin      : 0;
	cursor      : pointer;
}
.labelText {
	display     : flex;
	align-items : center;
}
.labelSpace {
	height      : 12px;
	border-left : 2px dotted grey;
	margin-left : 11px;
}
.speedUp {
	width           : 343px;
	height          : 152px;
	display         : flex;
	justify-content : space-between;
	flex-wrap       : wrap;
	background      : #F7F7FA;
	border-radius   : 8px;
	margin          : auto;
}
.lamp img {
	width  : 20px;
	height : 20px;
}
.lamp {
	width         : 48px;
	height        : 48px;
	margin        : 0 10px 0 8px;
	background    : linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
	box-shadow    : 0 0 2px rgba(37, 35, 44, 0.12), 0 1px 2px rgba(37, 35, 44, 0.12);
	border-radius : 50%;
	position      : absolute;
	top           : -24px;
}
.speedText {
	width       : 287px;
	height      : 36px;
	font-weight : 500;
	font-size   : 12px;
	line-height : 18px;
	color       : #25232C;
	text-align  : center;
	padding     : 36px 0;
}
.speedBtn {
	display         : flex;
	align-items     : center;
	justify-content : center;
	width           : 195px;
	height          : 48px;
	background      : linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
	box-shadow      : 0 0 2px rgba(37, 35, 44, 0.12), 0 1px 2px rgba(37, 35, 44, 0.12);
	border-radius   : 58px;
	font-weight     : bold;
	font-size       : 14px;
	line-height     : 16px;
	color           : #174DAF;
	margin-right    : 8px;
	border          : 0 !important;
}
.speedBtn img {
	width        : 18px;
	height       : 21px;
	margin-right : 7px;
}
h3 {
	font-weight : 900;
	font-size   : 18px;
	line-height : 28px;
	color       : #25232C;
	margin      : 40px 0 16px 0;
}
.errorDoc {
	width         : 343px;
	background    : #FFF5F5;
	border-radius : 8px;
	margin-bottom : 16px;
}
div.errorDoc:before {
	display       : block;
	width         : 24px;
	height        : 24px;
	position      : relative;
	bottom        : 14px;
	left          : 329px;
	content       : '';
	/* background    : url("../../IMAGE/JPCOM/ICON/docs_error.svg"); */
	margin-bottom : -29px;
}
.textDescription {
	font-weight : 500;
	font-size   : 12px;
	line-height : 18px;
	color       : #716F7D;
	padding     : 0 16px;
}
.textFileUpload {
	width : 180px;
}
.documentFile {
	height        : 56px;
	background    : #FFFFFF;
	border-radius : 8px;
	margin        : 12px 16px 8px 16px;
}
.fileThumbnail {
	width         : 52px;
	height        : 52px;
	background    : linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
	box-shadow    : 0 0 2px rgba(37, 35, 44, 0.12), 0 1px 2px rgba(37, 35, 44, 0.12);
	border-radius : 6px;
	margin        : 0 12px 0 2px;
}
.thumbnail{
	width         : 52px;
	height        : 52px;
	border-radius : 6px;
	margin        : 0 12px 0 16px;
}
.fileName {
	font-weight : 500;
	font-size   : 14px;
	line-height : 18px;
	color       : #25232C;
}
.dontOverflow{
	width: 100%;
	white-space   : nowrap;
	overflow      : hidden;
	text-overflow : ellipsis;
}
.errorDescription {
	font-weight : normal;
	font-size   : 10px;
	line-height : 18px;
	color       : #F94747;
}
.deleteBtn {
	display         : flex;
	align-items     : center;
	justify-content : center;
	width           : 40px;
	height          : 40px;
	margin-right    : 8px;
}
.deleteLink {
	line-height : 20px;
}
.trashImg {
	width  : 20px;
	height : 20px;
	fill   : #A29FB0;
}
.deleteBtn.show, .deleteBtn:hover {
	background    : #FFE3E3;
	border-radius : 50%;
}
.deleteBtn.show .trashImg, .deleteBtn:hover .trashImg{
	fill : #F94747;
}
.deleteDrop {
	top           : 8px !important;
	left          : 20px !important;
	width         : 230px;
	height        : 154px;
	background    : #FFFFFF;
	border        : none;
	box-shadow    : 0 16px 32px rgba(52, 49, 70, 0.08), 0 0 1px rgba(52, 49, 70, 0.08);
	border-radius : 8px;
	margin-right  : auto;
	margin-top    : 15px;
}
div.deleteDrop:before {
	content       : '';
	position      : absolute;
	top           : -8px;
	right         : 19px;
	border-left   : 8px solid transparent;
	border-right  : 8px solid transparent;
	border-bottom : 8px solid white;
}
.trashDropImg {
	width  : 20px;
	height : 20px;
	margin : 16px auto 16px 22px;
	fill   : #F94747;
}
.deleteDrop span {
	font-weight   : 500;
	font-size     : 15px;
	line-height   : 20px;
	padding       : 0 20px;
	margin-bottom : 10px;
}
.exclude {
	font-weight : bold;
	font-size   : 15px;
	line-height : 24px;
	color       : #F94747 !important;
	padding     : 0 20px 0 20px;
}
.cancel {
	font-weight : 500;
	font-size   : 15px;
	line-height : 24px;
	color       : #716F7D !important;
	cursor: pointer;
}
.uploadBlock {
	height        : 56px;
	background    : #FFFFFF;
	border-radius : 8px;
	margin        : 8px 16px 8px 16px;
	border        : 2px dashed #EAE8F3;
}
.uploadImg {
	width       : 20px;
	height      : 18px;
	margin-left : 18px;
}
.uploadText {
	font-weight  : 500;
	font-size    : 12px;
	line-height  : 16px;
	color        : #716F7D;
	padding-left : 10px;
}
.cameraImg {
	width  : 20px;
	height : 20px;
	fill   : #A29FB0;
}
.uploadBtn {
	display         : flex;
	align-items     : center;
	justify-content : center;
	width           : 48px;
	height          : 48px;
	background      : #F5F4FA;
	border-radius   : 4px;
	margin-right    : 2px;
}
.divUpload.show a.uploadBlock .uploadBtn, .divUpload:hover a.uploadBlock .uploadBtn {
	background : linear-gradient(180deg, #214FA4 0%, #0C3A8E 100%);
}
.divUpload.show a.uploadBlock .cameraImg, .divUpload:hover a.uploadBlock .cameraImg{
	fill : #FFFFFF;
}
.uploadDrop {
	left          : 3px !important;
	width         : 311px;
	height        : 526px;
	background    : #25232C;
	border        : none;
	box-shadow    : 0 16px 32px rgba(52, 49, 70, 0.08), 0 0 1px rgba(52, 49, 70, 0.08);
	border-radius : 8px;
	margin-right  : auto;
	margin-top    : 15px;
	font-weight   : 500;
	font-size     : 12px;
	line-height   : 18px;
	color         : #FFFFFF;
	z-index: 2000;
}
.uploadDrop[x-placement="top-end"] {
	top : -15px !important;
}
div.uploadDrop[x-placement="bottom-end"]:before {
	content       : '';
	position      : absolute;
	top           : -8px;
	right         : 19px;
	border-left   : 10px solid transparent;
	border-right  : 10px solid transparent;
	border-bottom : 10px solid #25232C;
}
div.uploadDrop[x-placement="top-end"]:before {
	content      : '';
	position     : absolute;
	bottom       : -9px;
	right        : 19px;
	border-left  : 10px solid transparent;
	border-right : 10px solid transparent;
	border-top   : 10px solid #25232C;
}
.important {
	font-weight : bold;
	font-size   : 14px;
	line-height : 18px;
	color       : #FFFFFF;
	margin      : 20px 0 11px 18px;
}
.importantImg {
	width        : 20px;
	height       : 20px;
	fill         : white;
	margin-right : 10px;
}
.buttonBlock {
	margin    : 13px 16px auto 16px;
	flex-wrap : wrap;
}
.photoBtn {
	display         : flex;
	align-items     : center;
	justify-content : center;
	width           : 100%;
	height          : 48px;
	background      : #25232C;
	border          : none;
	font-weight     : bold;
	font-size       : 14px;
	line-height     : 24px;
	color           : #FFFFFF;
}
.photoBtn:hover {
	background    : #FFFFFF;
	border-radius : 8px;
	color         : #214FA4;
}
.photoImg {
	width        : 20px;
	height       : 18px;
	fill         : white;
	margin-right : 10px;
}
.photoBtn:hover .photoImg {
	fill : #214FA4;
}
.submitDocuments {
	display       : flex;
	width         : 205px;
	height        : 40px;
	background    : #F7F7FA;
	border        : 1px solid #FFFFFF;
	border-radius : 8px;
	font-weight   : bold;
	font-size     : 14px;
	line-height   : 16px;
	color         : #39B65C !important;
	margin        : 16px 0 0 16px;
}
.submitImg {
	width  : 10px;
	height : 8px;
	margin : 0 7px 0 16px;
	fill   : #39B65C;
}
.submitDocuments.disabled {
	color : #BEBCC8 !important;
}
.submitDocuments.disabled .submitImg {
	fill : #BEBCC8;
}
.uploadDoc {
	width         : 100%;
	background    : linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
	box-shadow    : 0 0 2px rgba(37, 35, 44, 0.12), 0 1px 2px rgba(37, 35, 44, 0.12);
	border-radius : 8px;
	margin-bottom : 16px;
}
.uploadedDoc {
	width         : 343px;
	background    : linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
	box-shadow    : 0 0 2px rgba(37, 35, 44, 0.12), 0 1px 2px rgba(37, 35, 44, 0.12);
	border-radius : 8px;
	margin-bottom : 16px;
}
div.uploadedDoc:before {
	display       : block;
	width         : 28px;
	height        : 28px;
	position      : relative;
	bottom        : 14px;
	left          : 329px;
	content       : '';
	/* background    : url("../../IMAGE/JPCOM/ICON/clock.svg") no-repeat; */
	margin-bottom : -29px;
}
.approvedDoc {
	width         : 343px;
	background    : linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
	box-shadow    : 0 0 2px rgba(37, 35, 44, 0.12), 0 1px 2px rgba(37, 35, 44, 0.12);
	border-radius : 8px;
	margin-bottom : 16px;
}
div.approvedDoc:before {
	display       : block;
	width         : 28px;
	height        : 28px;
	position      : relative;
	bottom        : 14px;
	left          : 329px;
	content       : '';
	/* background    : url("../../IMAGE/JPCOM/ICON/check.svg") no-repeat; */
	margin-bottom : -29px;
}
.uploadedDoc .documentFile, .approvedDoc .documentFile {
	background : #F7F7FA;
}
.editDocuments {
	font-weight : bold;
	font-size   : 14px;
	line-height : 16px;
	color       : #174DAF;
}
.editDocuments.disabled {
	color : #BEBCC8;
}
.pencilImg {
	width  : 16px;
	height : 16px;
	margin : 0 5px 0 16px;
	fill   : #174DAF;
}
.editDocuments.disabled .pencilImg {
	fill : #BEBCC8;
}
.managerHelp {
	position      : sticky;
	bottom        : 30px;
	right         : 30px;
	width         : 250px;
	height        : 56px;
	background    : #FFFFFF;
	border-radius : 80px;
	margin        : 30px -140px 30px auto;
}
.fakeMargin {
	height : 0
}
.photo {
	width         : 50px;
	height        : 50px;
	border-radius : 50%;
	margin-left   : 4px;
}
.doubts {
	font-weight : 500;
	font-size   : 12px;
	line-height : 16px;
	color       : #25232C;
}
.phone {
	font-weight : bold;
	font-size   : 12px;
	line-height : 16px;
	color       : #EE6149 !important;
}
.whatsapp {
	font-weight : bold;
	font-size   : 12px;
	line-height : 16px;
	color       : #4AC959 !important;
}
.helpImg {
	width  : 12px;
	height : 12px;
}
/* Speed Up Modal */
.modal-dialog {
	max-width : 343px !important;
}
.chron {
	display         : flex;
	align-items     : center;
	justify-content : center;
	width           : 40px;
	height          : 40px;
	background      : #E9EEFF;
	border-radius   : 50%;
}
.chronImg {
	width  : 18px;
	height : 20px;
}
.speedUpTitle {
	display         : flex;
	align-items     : center;
	justify-content : center;
	font-weight     : 900;
	font-size       : 24px;
	line-height     : 36px;
	color           : #25232C;
	margin          : 24px 0 0 0;
}
.speedUpDesc {
	display     : flex;
	align-items : center;
	text-align  : center;
	font-weight : normal;
	font-size   : 16px;
	line-height : 26px;
	color       : #716F7D;
	margin      : 8px 16px 32px 16px;
}
.form-group {
	margin-bottom : 8px !important;
}
.form-control {
	font-weight   : 500;
	font-size     : 14px;
	line-height   : 24px;
	color         : #25232C !important;
	height        : 48px;
	background    : #FFF;
	border        : 2px solid #EAE8F3;
	box-sizing    : border-box;
	border-radius : 8px;
}
.invalid-feedback {
	line-height : 16px;
	margin      : 0;
}
.termsAcceptance {
	font-weight : 500;
	font-size   : 14px;
	line-height : 24px;
	color       : #25232C;
}
.termsAcceptance a {
	color : #174DAF !important;
}
.speedUpBtn {
	width         : 268px;
	height        : 48px;
	background    : linear-gradient(180deg, #214FA4 0%, #0C3A8E 100%);
	border-radius : 8px;
	border        : 0;
	font-weight   : bold;
	font-size     : 14px;
	line-height   : 16px;
	color         : #FFFFFF;
	margin        : 16px 0 40px 0;
}
.speedUpBtn.disabled {
	font-size     : 16px;
	font-weight   : bold;
	cursor        : default;
	border-radius : 8px;
	color         : rgb(172, 172, 172);
	background    : linear-gradient(180deg, #F8F8FF 0%, #DCDCDC 100%);
	border        : 0;
}
.form-input-checkbox {
	width  : 20px;
	height : 20px;
}
.anchor {
	position   : absolute;
	transform  : translateY(-30vh);
	visibility : hidden;
}
@media (min-width : 768px) {
	.title {
		min-height : calc(100vh - 177px);
		height     : 100%;
		margin     : 0 32px;
	}
	.card-body {
		margin    : 39px 35px 40px 35px;
		max-width : 100%;
	}
	.docTitle {
		padding-top   : 24px;
		margin-bottom : 24px;
	}
	.errorDoc {
		width : 552px;
	}
	div.errorDoc:before {
		left : 538px;
	}
	.approvedDoc {
		width : 552px;
	}
	div.approvedDoc:before {
		left : 538px;
	}
	.uploadDoc {
		width : 100%;
		padding-bottom: 12px;
	}
	.uploadedDoc {
		width : 552px;
	}
	div.uploadedDoc:before {
		left : 538px;
	}
	.modal-dialog {
		max-width : 552px !important;
	}
	.speedUp {
		width         : 100%;
		height        : 64px;
		border-radius : 68px;
		flex-wrap     : nowrap;
		margin        : 0 0 30px 0 !important;
		align-items   : center;
	}
	.lamp {
		position : static;
		top      : 0;
	}
	.speedText {
		padding-top    : 0;
		padding-bottom : 0;
		text-align     : left;
	}
	.speedUpDesc {
		margin : 8px 104px 32px 104px;
	}
	/* .form-group {
		padding       : 0 144px 8px 144px !important;
		margin-bottom : 8px !important;
	} */
	.uploadDrop {
		width  : 520px;
		height : 371px;
	}
	.buttonBlock {
		margin    : 13px auto auto 16px;
		flex-wrap : nowrap;
	}
	.photoBtn {
		width : 177px;
	}
	.textFileUpload {
		width : 390px;
	}
}
@media (min-width : 1200px) {
	.title {
		margin : 0 156px;
	}
	.card-body {
		margin : 39px 35px 40px 35px;
	}
	.docTitle {
		padding-top : 56px;
	}
	.documentSection {
		padding : 0 15px 0 0;
	}

}
