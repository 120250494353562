.footer-content {
	margin     : auto;
	padding    : 28px 16px 32px 16px;
	max-width  : 100vw;
	background : linear-gradient(179.01deg, #F5F4FA 0.85%, rgba(244, 242, 250, 1) 98.54%);
	z-index    : 1030;
	position: relative;
	right: 0;
	bottom: 0;
	left: 0;
}
.footer-link {
	font-style    : normal;
	font-weight   : bold;
	font-size     : 12px;
	line-height   : 18px;
	padding-right : 24px;
	color         : #174DAF !important;
}
.footer-container div {
	display     : flex;
	align-items : center;
}
.footer-container div:nth-child(1), .footer-container div:nth-child(4) {
	padding-bottom : 24px;
}
.footer-container div:nth-child(2), .footer-container div:nth-child(3) {
	padding-bottom : 16px;
}
.footer-container div:nth-child(6) {
	padding-top    : 24px;
	padding-bottom : 24px;
}
.footer-container div:nth-child(9) {
	padding-top    : 24px;
	padding-bottom : 10px;
}
.footer-container div:nth-child(11) {
	padding-bottom : 24px;
}
.slash {
	width        : calc(100vw - 16px);
	margin-left  : 16px;
	/*margin-right: 16px;*/
	margin-right : 16px;
	height       : 1px;
	background   : #ECEAF3;
}
.allRights {
	font-weight : normal;
	font-size   : 12px;
	line-height : 18px;
	color       : #716F7D;
}
.contacts-home {
	font-weight   : bold;
	font-size     : 16px;
	line-height   : 16px;
	color         : #174DAF;
	padding-right : 13px;
}
.contacts-phone {
	font-style    : normal;
	font-weight   : 900;
	font-size     : 18px;
	line-height   : 28px;
	color         : #25232C !important;
	padding-right : 13px;
}
.contacts-whatsapp {
	font-style    : normal;
	font-weight   : 900;
	font-size     : 18px;
	line-height   : 28px;
	color         : #4AC959 !important;
	padding-right : 13px;
}
.icon-contacts {
	width       : 20px;
	height      : 20px;
	margin-left : auto;
}
@media (min-width : 768px) {
	.col-md-8 {
		-ms-flex  : 0 0 66.666667% !important;
		flex      : 0 0 67.666667% !important;
		max-width : 67.666667% !important;
	}
	.col-md-4 {
		-ms-flex  : 0 0 32.333333% !important;
		flex      : 0 0 32.333333% !important;
		max-width : 32.333333% !important;
	}
	.footer-content {
		padding : 1.4rem 48px .4375rem;
		height  : 177px;
		/*position: fixed;*/
	}
	.footer-container div:nth-child(1) {
		padding-left  : 0;
		padding-right : 0;
	}
	.footer-container div:nth-child(6) {
		justify-content : flex-end;
		padding         : 0 0 24px;
	}
	.footer-container div:nth-child(8) {
		padding : 0 0 16px;
	}
	.footer-container div:nth-child(9) {
		justify-content : flex-end;
		padding         : 0 0 16px;
	}
	.footer-container div:nth-child(10) {
		padding-left  : 0;
		padding-right : 0;
	}
	.footer-container div:nth-child(11) {
		justify-content : flex-end;
		padding         : 0;
	}
	.icon-contacts {
		margin-left : 0;
	}
}
@media (min-width : 1200px) {
	.footer-content {
		padding : 1.4rem 1.05rem .4375rem;
	}
	.footer-container {
		padding : 0 156px;
	}
}
