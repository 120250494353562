.navbar {
	margin     : auto;
	padding    : 0 35px;
	max-width  : 100vw;
	height     : 64px;
	background : linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
}
.myNav {
	max-width : 100%;
}

button.userSettings {
	background-image : url("../public/assets/ICON/userSettings.svg");
	width            : 48px;
	height           : 48px;
}
button.missingDocs.userSettings {
	background    : url("../public/assets/ICON/userSettingsMissing.svg") no-repeat !important;
	border-radius : 0;
	height        : 50px;
}
button.userSettings.open {
	background-image : url("../public/assets/ICON/userSettings-active.svg");
	width            : 48px;
	height           : 48px;
}
button.missingDocs.userSettings.open {
	background    : url("../public/assets/ICON/userSettingsMissing-active.svg") no-repeat !important;
	border-radius : 0;
	height        : 50px;
}
button.userSettings.open::after {
	display        : inline-block;
	margin-left    : .255em;
	vertical-align : .255em;
	content        : "";
	border-top     : 0;
	border-right   : .3em solid transparent;
	border-bottom  : .3em solid;
	border-left    : .3em solid transparent;
}
button.dropdown-toggle {
	font-weight : 900;
	font-size   : 14px;
	line-height : 24px;
	color       : #FFFFFF;
}
button.userSettings {
	border        : 0;
	border-radius : 50%;
}
button.userSettings a.dropdown-toggle {
	font-weight : 900;
	font-size   : 14px;
	line-height : 24px;
	color       : #FFFFFF !important;
}
button.userSettings:focus {
	outline    : none;
	box-shadow : none;
}
.menuPage {
	background      : #FFFFFF;
	box-shadow      : 0 16px 32px rgba(52, 49, 70, 0.08), 0 0 1px rgba(52, 49, 70, 0.08);
	background-size : 100% 100%;
	border-radius   : 8px 8px 0 0;
	margin-top      : 17px;
}
.menuPage:before {
	position      : absolute;
	top           : 65px;
	right         : 30px;
	content       : '';
	border-left   : 8px solid transparent;
	border-right  : 8px solid transparent;
	border-bottom : 8px solid white;
}
.menuPage a {
	font-weight : 600;
	font-size   : 15px;
	line-height : 24px;
	color       : #25232C;
}
.menuPage a:hover {
	color : #25232C;
}
.navbar .navbar-nav.menuPage .nav-item > .nav-link {
	padding : 12px 0;
	margin  : 0;
}
.menuPage li:nth-child(1) {
	margin-top : 18px;
}
.nav-item.active a.nav-link {
	font-weight : bold !important;
	color       : #174DAF !important;
}
.settingsHeader {
	color        : #A29FB0 !important;
	padding-left : 17px !important;
}
.nav-icon {
	width        : 18px;
	height       : 18px;
	margin-right : 10px !important;
	margin-left  : 17px;
}
.nav-icon.missingDocs {
	width  : 24px;
	height : 22px;
}
.nav-icon.missingProcess {
	width         : 24px;
	height        : 20px;
	margin-bottom : 4px;
}
.myNavLast {
	border-radius : 0 0 8px 8px;
	background    : #F7F7FA;
}
.myNavLast a {
	font-weight : 600;
	font-size   : 15px;
	line-height : 24px;
	color       : #25232C;
}
.myNavLast a:hover {
	color : #25232C;
}
.navbar .navbar-nav.myNavLast .nav-item > .nav-link {
	padding : 12px 0 12px 18px;
	margin  : 0;
}
i {
	margin-right : 10px;
}
/* Change Password */
.modal-dialog {
	margin : 0 auto !important;
}
.key {
	display         : flex;
	align-items     : center;
	justify-content : center;
	width           : 40px;
	height          : 40px;
	background      : #FAFAD2;
	border-radius   : 50%;
}
.changePasswordTitle {
	display         : flex;
	align-items     : center;
	justify-content : center;
	font-weight     : 900;
	font-size       : 24px;
	line-height     : 36px;
	color           : #25232C;
	margin          : 24px 0 0 0;
}
.form-groupPassword {
	padding       : 0 16px 8px 16px !important;
	margin-bottom : 8px !important;
}
.form-control {
	font-weight   : 500;
	font-size     : 14px;
	line-height   : 24px;
	color         : #25232C !important;
	height        : 48px;
	background    : #FFF;
	border        : 2px solid #EAE8F3;
	box-sizing    : border-box;
	border-radius : 8px;
}
.invalid-feedback {
	line-height : 16px;
	margin      : 0;
}
.changePasswordBtn {
	width         : 268px;
	height        : 48px;
	background    : linear-gradient(180deg, #214FA4 0%, #0C3A8E 100%);
	border-radius : 8px;
	border        : 0;
	font-weight   : bold;
	font-size     : 14px;
	line-height   : 16px;
	color         : #FFFFFF;
	margin        : 16px 0 0 0;
}
.changePasswordBtn.disabled {
	font-size     : 16px;
	font-weight   : bold;
	cursor        : default;
	border-radius : 8px;
	color         : rgb(172, 172, 172);
	background    : linear-gradient(180deg, #F8F8FF 0%, #DCDCDC 100%);
	border        : 0;
}
/* success modal */
.description {
	display         : flex;
	align-items     : center;
	justify-content : center;
	font-weight     : normal;
	text-align      : center;
	font-size       : 16px;
	line-height     : 26px;
	color           : #716F7D;
	margin          : 8px 0 32px 0;
}
.descriptionFail {
	display         : flex;
	align-items     : center;
	justify-content : center;
	font-weight     : normal;
	text-align      : center;
	font-size       : 16px;
	line-height     : 26px;
	color           : #716F7D;
	margin          : 8px 0 32px 0;
}
@media (min-width : 576px) and (max-width : 991px) {
	.menuPage {
		width  : 193px;
		margin : 16px 0 0 auto;
	}
	.menuPage:before {
		right : 46px;
	}
	.myNavLast {
		width       : 193px;
		margin-left : auto;
	}
}
@media (min-width : 768px) {
	.navbar {
		padding: 0 35px !important;
	}
	.form-groupPassword {
		padding       : 0 144px 8px 144px !important;
		margin-bottom : 8px !important;
	}
	.description {
		padding : 0 104px 0 105px !important;
	}
	.descriptionFail {
		padding : 0 75px!important;
	}
}
@media (min-width : 992px) {
	.navbar {
		padding: 0 35px !important;
	}
	.myNav {
		align-items : center;
	}
	.menuPage {
		padding-right : 5px;
		box-shadow    : none;
		margin-top    : 0;
		margin-left   : auto;
	}
	.menuPage:before {
		content : none;
	}
	ul.navbar-nav.menuPage li.nav-item a.nav-link {
		padding : 0 27px 0 0 !important;
	}
	.userSettings .nav-item {
		background-image : url("../public/assets/ICON/userSettings.svg");
		width            : 48px;
		height           : 48px;
	}
	.userSettings a.dropdown-toggle {
		font-weight : 900;
		font-size   : 14px;
		line-height : 24px;
		color       : #FFFFFF !important;
	}
	.nav-item.show {
		background-image : url("../public/assets/ICON/userSettings-active.svg");
		width            : 48px;
		height           : 48px;
	}
	.nav-item.show a.dropdown-toggle::after {
		display        : inline-block;
		margin-left    : .255em;
		vertical-align : .255em;
		content        : "";
		border-top     : 0;
		border-right   : .3em solid transparent;
		border-bottom  : .3em solid;
		border-left    : .3em solid transparent;
	}
	.settingsBody {
		color  : black;
		cursor : pointer;
	}
	.nav-item > .dropdown-menu {
		width                 : 193px;
		background-image      : url('../public/assets/DASHBOARD/dropdown-menu-right.svg');
		background-repeat     : no-repeat;
		background-position-y : -16px;
		background-position-x : -32px;
		background-color      : transparent !important;
		opacity               : 0;
		pointer-events        : none;
		-webkit-transform     : translate(0, .625rem);
		transform             : translate(0, .625rem) !important;
		border                : 0 !important;
		font-size             : 15px !important;
		font-weight           : 500 !important;
	}
	.nav-item.show > .dropdown-menu {
		opacity           : 1;
		-webkit-transform : translate(0, 0);
		transform         : translate(0, 0) !important;
		pointer-events    : auto;
		margin-right      : 24px;
		margin-top        : 0 !important;
	}
	.nav-item > .dropdown-menu a:hover {
		font-weight      : bold;
		background-color : #E8E8E8;
		border-radius    : 4px;
		color            : black;
	}
	.nav-item > .dropdown-menu a {
		width       : 100%;
		/*transition: visibility 0.5 s ease-out;*/
		line-height : 1.5rem;
		padding     : .4375rem 1rem 0 1rem !important;
		white-space : nowrap;
	}
	.nav-icon {
		margin-left : 0 !important;
	}
	/* Dashboard settings */
	.nav-item > .dash-icon {
		padding-right : 3.75rem !important;
	}
	.nav-item > .dash-icon:before {
		content           : "";
		background-image  : url('../public/assets/ICON/dash.svg');
		width             : 1.125rem;
		height            : 1.125rem;
		display           : inline-block;
		position          : relative;
		top               : 6px;
		right             : 10px;
		background-size   : contain;
		background-repeat : no-repeat;
		background-color  : transparent;
	}
	.nav-item > .dash-icon.router-link-active {
		color       : #174DAF;
		font-weight : bold;
	}
	.nav-item > .dash-icon.router-link-active:before {
		background-image : url('../public/assets/ICON/dash-blue.svg');
	}
}
